import Shuffle from "shufflejs";
import { Viewer } from "photo-sphere-viewer";
import "./jquery.flashy.min.js";
const slick = require("slick-carousel");

const prodURL = "https://modulor-vr.com";

document.addEventListener("DOMContentLoaded", function () {
  const mixed = document.querySelector(".mixed");
  if (mixed) {
    $(".mixed").flashy();
  }

  const portfolioItemsWrapper = document.querySelector(".portfolio-items");

  if (portfolioItemsWrapper) {
    $(".portfolio-items").slick({
      slidesToScroll: 1,
      draggable: false,
      arrows: true,
      dots: false,
      infinite: false,
      variableWidth: false,
      nextArrow: ".portfolio--next",
      prevArrow: ".portfolio--prev",
    });

    $("div[data-slider]").click(function (e) {
      e.preventDefault();
      var slideno = $(this).data("slider");
      $(".portfolio-items").slick("slickGoTo", slideno);
    });

    // ============

    const showVR = document.querySelector(".show-item-vr"),
      showScan3D = document.querySelector(".show-item-skanowanie-3d"),
      showAllItems = document.querySelector(".show-items-all"),
      showVirtualSpacers = document.querySelector(
        ".show-item-wirtualne-spacery"
      ),
      showCinemaVR = document.querySelector(".show-item-kino-vr");

    showVR.addEventListener("click", () => {
      shuffleInstance.filter("360");
    });

    if (showScan3D) {
      showScan3D.addEventListener("click", () => {
        shuffleInstance.filter("matterport");
      });
    }

    if (showAllItems) {
      showAllItems.addEventListener("click", () => {
        shuffleInstance.filter();
      });
    }

    if (showVirtualSpacers) {
      showVirtualSpacers.addEventListener("click", () => {
        shuffleInstance.filter("wirtualneSpacery");
      });
    }

    if (showCinemaVR) {
      showCinemaVR.addEventListener("click", () => {
        shuffleInstance.filter("kinoVR");
      });
    }
  }

  if (document.querySelector(".portfolio-slider-for")) {
    var element = document.querySelector(".portfolio-slider-for");

    var sizer = element.querySelector(".portfolio-slider-for");

    var shuffleInstance = new Shuffle(element, {
      itemSelector: ".portfolio-item__top",
      sizer: sizer,
    });
  }

  function addDataToSlickSlide(elem) {
    const allElements = document.querySelectorAll(elem);
    $(allElements).each(function () {
      $(this)
        .parent()
        .parent()
        .attr("data-groups", $(this).attr("data-groups"));
    });
  }

  addDataToSlickSlide(".portfolio-item");
});
